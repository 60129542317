import { isVisibleWithViewOptions } from '@helpers/viewOptionsHelper';
import { flattenTree } from '@src/app/utils';
import { getRegions, getEducationLevels } from '@src/components/constants';

export const filterTeasers = (teasers, userViewOptions) =>
  teasers.filter((e) => isVisibleWithViewOptions(e, userViewOptions));

export const checkIfOutOfViewOptions = (theme, userViewOptions) => {
  const items = flattenTree(theme, false);
  // ignore items of type UNSTRUCTURED_DOCUMENTS (shared documents) when evaluating the visibility, as they can have viewOptions that don't match the page
  if (
    !items
      .filter((e) => e.type !== 'UNSTRUCTURED_DOCUMENT')
      .some((e) => isVisibleWithViewOptions(e, userViewOptions))
  ) {
    return true;
  }

  const currentPath = items.find(
    (item) =>
      item?.pagePath?.toLowerCase() === decodeURI(window.location.pathname).toLowerCase() ||
      item?.path?.toLowerCase() === decodeURI(window.location.pathname).toLowerCase() ||
      item?.href === window.location.pathname ||
      (theme?.parent?.multipleAlternativeMenus && theme) // in multipleAlternativesMenu we don't have a path (because the showed page is represented in the urlParam "tab") so, for those cases, we only want to check the visibility of the currentPage, thats why we return here the page send as param to "checkIfOutOfViewOptions", to check the visibility only in that page
  );

  if (currentPath && !isVisibleWithViewOptions(currentPath, userViewOptions)) {
    return true;
  }
  return false;
};

export const getUserDefaultRegions = (diocesesHrefs) => {
  const preferedRegions = getRegions();
  if (diocesesHrefs.length > 0) {
    preferedRegions
      .filter((region) => diocesesHrefs.includes(region.href))
      .forEach((region) => {
        region.checked = true;
      });
  }

  return preferedRegions;
};

export const getUserDefaultEducationLevels = (
  userOusMainstructuresHrefs,
  VOSOus = [],
  SAMOus = []
) => {
  const cvoOrBoardingOus = VOSOus.filter((VOSou) =>
    ['CVO', 'BOARDING'].includes(VOSou.$$meta.type)
  );

  const hasLeersteuncentra = SAMOus.some((SAMOu) => SAMOu.type === 'LEERSTEUNCENTRUM');

  if (
    userOusMainstructuresHrefs.length === 0 &&
    cvoOrBoardingOus.length === 0 &&
    !hasLeersteuncentra
  )
    return getEducationLevels();

  return getEducationLevels().map((educationLevel) => {
    const shouldBeSelected =
      educationLevel.mainstructures.some((mainstructure) =>
        userOusMainstructuresHrefs.includes(mainstructure)
      ) ||
      // DISCLAIMER: this is legacy code, I dont know nothing about it
      Boolean(cvoOrBoardingOus.find((org) => org.$$meta.type === educationLevel.ouType)) ||
      // Users from LEERSTEUNCENTRUM get (bu)bao and (bu)so selected, see RT-706
      (hasLeersteuncentra &&
        [
          '/namedsets/6366f518-1a00-4445-b064-2df1d889fa0b',
          '/namedsets/0dba1aec-ee5d-4c85-98ab-1bef27afb673',
        ].includes(educationLevel.mainstructuresOuTypeCombination));

    return {
      ...educationLevel,
      checked: shouldBeSelected,
    };
  });
};
