import {
  selectIsUserReadyForAnalytics,
  getUserKey,
  getLoginMethod,
  selectSelectedRegionTags,
  selectSelectedEducationLevelTags,
  selectUserTargetGroup,
} from '@src/reduxStore/user/userSelectors';
import { stateMatch } from 'redux-awaitable-state';
import { analyticCookiesAccepted } from '@helpers/cookieHelper';
import { isPageThemeEventRequired } from '@src/helpers/themeHelper';
import { getPageThemesForPiwik } from '../theme/themeSelectors';

const settings = require('../../config/settings');

const trackPiwikEvent = (eventName, props) => async (dispatch, getState) => {
  if (!(settings.piwikAnalytics.enabled && analyticCookiesAccepted())) return;

  await stateMatch(
    (state) =>
      selectIsUserReadyForAnalytics(state) &&
      state.analytics.isPiwikTagReady &&
      state.analytics.breadcrumbsReady
  );

  const userKey = getUserKey(getState());
  const userTargetGroup = selectUserTargetGroup(getState());
  const userRegion = selectSelectedRegionTags(getState()).join('.');
  const userEduLevel = selectSelectedEducationLevelTags(getState()).join('.');
  const userLoginMethod = getLoginMethod(getState());

  const commonProperties = {
    ...getState().analytics.commonProperties,
    user_id: userKey || undefined,
    user_authstatus: userKey ? 'authenticated' : 'anonymous',
    user_target_group: userTargetGroup?.toLowerCase() || undefined, // when we don't have a value it needs to be undefined
    user_region: (userRegion.length > 0 && userRegion) || undefined,
    user_edu_level: (userEduLevel.length > 0 && userEduLevel) || undefined,
    page_title: (eventName === 'view_page' && document.title?.toLowerCase()) || undefined,
    user_login_method: userLoginMethod?.toLowerCase() || undefined,
  };

  // page_themes parameter should be only sent in some events.
  if (isPageThemeEventRequired(eventName)) {
    commonProperties.page_theme = getPageThemesForPiwik(getState(), eventName);
  }

  const eventProps = {
    event: eventName,
    ...commonProperties,
    ...props,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventProps);

  console.log(
    'piwik-event-dispatch',
    (['test', 'acc'].includes(settings.environment) && eventProps) || ''
  );
};

export default trackPiwikEvent;
