import React from 'react';

const VakkenPaginaTabs = ({ activeTabId, setActiveTab }) => (
  <ul className="nav nav-tabs">
    <li>
      <a
        data-toggle="tab"
        className={`${activeTabId === 'first-grade' && 'active'}`}
        aria-expanded={activeTabId === 'first-grade'}
        onClick={() => setActiveTab('first-grade')}
      >
        1ste graad
      </a>
    </li>
    <li>
      <a
        className={`${activeTabId === 'second-grade' && 'active'}`}
        data-toggle="tab"
        aria-expanded={activeTabId === 'second-grade'}
        onClick={() => setActiveTab('second-grade')}
      >
        2de graad
      </a>
    </li>
    <li>
      <a
        data-toggle="tab"
        className={`${activeTabId === 'thirth-grade' && 'active'}`}
        aria-expanded={activeTabId === 'thirth-grade'}
        onClick={() => setActiveTab('thirth-grade')}
      >
        3de graad
      </a>
    </li>
    <li>
      <a
        data-toggle="tab"
        className={`${activeTabId === 'seventh-year' && 'active'}`}
        aria-expanded={activeTabId === 'seventh-year'}
        onClick={() => setActiveTab('seventh-year')}
      >
        7de Leerjaar
      </a>
    </li>
    <li>
      <a
        data-toggle="tab"
        className={`${activeTabId === 'thirth-grade-old' && 'active'}`}
        aria-expanded={activeTabId === 'thirth-grade-old'}
        onClick={() => setActiveTab('thirth-grade-old')}
      >
        3de graad oude structuur
      </a>
    </li>
  </ul>
);

export default VakkenPaginaTabs;
