import {
  API as curriculaSelectorAPI,
  getCurriculumThemes,
  getByStudyProgrammeTabData,
} from '@kathondvla/curricula-selector';
import { cachedContentApi, contentApi, searchApi } from '@src/app/apiConfig';
import { isPreview } from '@src/helpers/previewHelper';

const sriCommonUtils = require('@kathondvla/sri-client/common-utils');

/**
 * This function returns the current hash for the content-api item. In case we are on previewMode
 * the hash will be ignored
 * @param {*} contentHref
 * @returns {string}
 */
const getContentHash = async (contentHref) =>
  cachedContentApi.get(`${contentHref}/hash`, {}).then((resp) => resp?.hash);

/**
 * This function will build the headers for the API calls if needed
 * @param {string} contentHref
 * @returns
 */
const getContentApiHeaders = async (contentHref = null) => {
  // Question for the future: shall we not be reaching cached-content-api when in the following case?
  // I guess the goal is to return "not-cached" elements (?)
  if (isPreview()) {
    return {
      headers: { 'VSKO-Resource-Hash': new Date().toISOString() },
    };
  }

  if (!contentHref) return {};

  const contentHash = await getContentHash(contentHref);
  if (!contentHash) return {};

  return {
    headers: { 'VSKO-Resource-Hash': contentHash },
  };
};

/**
 * This method returns all the ProThemes from the content-api
 * @returns {Promise<{Array<*>}>}
 */
export const getProThemes = async () =>
  cachedContentApi.getAll(
    '/content',
    {
      tagsOverlaps: 'WEBPAGE2,PRO_PAGE',
      limit: 5000,
    },
    await getContentApiHeaders()
  );

/**
 * This API call will return the proTheme or shared database item href for the given contentHref by going up on the tree from the given contentHref.
 * @param {string} contentHref
 */
export const getContentRootHref = async (contentHref) =>
  cachedContentApi
    .getAll(
      '/content',
      {
        leaf: contentHref,
        tagsOverlaps: 'WEBPAGE2,PRO_PAGE,SHARED_MINI_DATABASE_ITEM',
        expand: 'NONE',
        limit: 5000,
      },
      await getContentApiHeaders()
    )
    .then((resp) => resp[0]?.href);

/**
 * This method returns a "customized" version of all the STUDY_PROGRAMMEs that include (for each of them):
 *  - STUDY_PROGRAMME_GROUPs
 *  - EDUCATION_TYPEs
 * And inside of the EDUCATION_TYPEs you have available the "issued" PRO-themes tagged with the CURRICULUM_THEMEs that belong to every EDUCATION_TYPE
 * In the day we are creating this it is only used to build the vakken-pagina page, where we need to show leerplan pages (PRO-theme tagged with a CURRICULUM_THEME) grouped by EDUCATION_TYPEs and STUDY_PROGRAMMEs.
 * Also we build this customised array using functions from the curricula-selector, so we ensure to have a "single source of truth"
 * @param {Array.<*>} proThemes
 * @returns {Promise<{Array<*>}>}
 */
export const getStudyProgrammes = async (proThemes) => {
  const [referenceFrame, llinkidCurriculums, allTypesOfEducation, allStudyProgrammes] =
    await Promise.all([
      curriculaSelectorAPI.getSectionDidactiekEnLeerplannen(cachedContentApi),
      curriculaSelectorAPI.getLlinkidCurriculums(cachedContentApi),
      curriculaSelectorAPI.getAllEducationTypes(cachedContentApi),
      curriculaSelectorAPI.getAllStudyProgrammes(cachedContentApi),
    ]);

  const curriculumThemes = getCurriculumThemes(referenceFrame);

  const studyProgrammes = getByStudyProgrammeTabData(
    llinkidCurriculums,
    allTypesOfEducation,
    allStudyProgrammes,
    curriculumThemes
  );

  return studyProgrammes
    .map((studyProgramme) => ({
      ...studyProgramme,
      ...(studyProgramme.educationTypes?.length && {
        educationTypes: studyProgramme.educationTypes.map((educationType) => ({
          ...educationType,
          leerplanPages: educationType.curriculumThemesHrefs
            .reduce((acc, curr) => {
              // a leerplan page is a PRO-theme tagged with a CURRICULUM_THEME
              const leerplanPage = proThemes.find(
                (proTheme) => proTheme.themes?.includes(curr) && proTheme.issued
              );
              if (leerplanPage) acc.push(leerplanPage);
              return acc;
            }, [])
            .sort((a, b) => a.title.localeCompare(b.title)),
        })),
      }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));
};

/**
 * This method returns the content for a given hrefs
 * @param {Array.<string>} contentHrefs
 * @returns {Promise<{Array<*>}>}
 */
export const getCachedContents = async (contentHrefs) =>
  cachedContentApi.getAll('/content', {
    hrefs: contentHrefs,
    limit: 5000,
  });

/**
 * This function will return all the descendant items for a given contentHref with the possibility of return only a subset of types of them
 * @param {string} contentHref
 * @param {Array.<string>} contentTypes
 * @param {Array.<string>} userAccess
 * @returns {Promise<{Array<*>}>}
 */
export const getHrefDescendantsByType = async (contentHref, contentTypes = [], userAccess = []) => {
  const params = {
    root: contentHref,
    typeIn: contentTypes.join(','),
    limit: 6000,
  };

  const headers = await getContentApiHeaders(contentHref);

  return cachedContentApi.getAll('/content', params, headers).catch(() => {
    // if we got here it means the response is including results that are not public. We will get a 401 in this case because cached api doesn't handle auth headers.

    if (userAccess.length) {
      if (userAccess.length === 1) {
        // if the user only has one "permission" it means it will be the public one, so we call again the cached api because with the new param we are sure the response won't include any "non-public" element so it won't fail
        return cachedContentApi.getAll(
          '/content',
          { ...params, accessibleBy: userAccess.join(',') },
          headers
        );
      }

      // if the user has more than one "permission" we can't do anything else than calling the non-cached API, which will deal with auth headers and include protected results (if they match with user permissions)
      return contentApi.getAll(
        '/content',
        { ...params, accessibleBy: userAccess.join(',') },
        headers
      );
    }

    console.error('contentDataAccess:getHrefDescendantsByType : userAccess empty', {
      contentHref,
      contentTypes,
      userAccess,
    });
    return [];
  });
};

/**
 * This function will call the searchAPI with some given parameters. If we provide a "nextLink" parameter it will take preference over the "searchParameters" parameter, meaning, it will call the searchApi using that "nextLink" directly
 * Because of some url length problems we had in the past there is a system in place that will use the "post" API in searchAPI, instead of the "get", when the url is longer than 1000 characters
 * @param {*} searchParameters
 * @param {string | undefined} nextLink
 * @param {* | undefined} options
 * @returns {Promise<{results: Array.<*>, $$meta: {count: number}}}
 */
export const getAPISearchResults = async (
  searchParameters,
  nextLink = undefined,
  options = { timeout: 0 }
) => {
  try {
    const stringUrl = nextLink || sriCommonUtils.parametersToString('/search', searchParameters);
    if (stringUrl.length > 1000) {
      return await searchApi.post(
        '/search/payload',
        {
          href: nextLink || stringUrl,
        },
        options
      );
    }

    if (nextLink) {
      return await searchApi.getRaw(nextLink);
    }
    return await searchApi.getRaw('/search', searchParameters, options);
  } catch (error) {
    console.error(error);
    return {
      results: [],
      $$meta: {
        count: 0,
      },
    };
  }
};
