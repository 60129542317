import { useEffect, useCallback } from 'react';
import { flattenTree } from '@src/app/utils';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import store from '@src/reduxStore/store';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import { getNameAndExtension } from '@src/app/contentUtils';
import { initCurrentTheme } from '@src/reduxStore/theme/themeSlice';
import { stateMatch } from 'redux-awaitable-state';
import {
  selectCurrentThemeTree,
  selectTreeLoadingStatus,
} from '@src/reduxStore/theme/themeSelectors';
import { loaderStart, loaderEnd } from '@src/helpers/loaderHelper';
import { getContentRootHref } from '@src/reduxStore/content/contentDataAccess';
import { buildTreeFromHref } from '@src/helpers/utils';
import { getUserAccess } from '@src/reduxStore/user/userSelectors';

const { isPreview } = require('@src/helpers/previewHelper');

const useLinks = (item, domContainerRef) => {
  const routerService = getAngularService('routerService');
  const itemContents = flattenTree(item);

  const followLink = useCallback(
    async (e) => {
      const { link } = e.target.closest('a').dataset;
      if (!link) return;
      const itemOnPage = itemContents.find((i) => i.href === link);

      if (itemOnPage) {
        const target = document.getElementById(itemOnPage.key);
        let y = target.getBoundingClientRect().top + window.pageYOffset - 50;
        const menu = document.getElementById('menu');
        if (getComputedStyle(menu).position === 'static') {
          y -= menu.offsetHeight;
        }
        window.scrollTo({ top: y, behavior: 'smooth' });
      } else {
        loaderStart();
        let contentRootHref = await getContentRootHref(link);
        if (!contentRootHref && isPreview()) {
          // this could happen if the element that the redactie user linked is not saved on the DB
          // Eg.: An user created a link to another block on the same document but that block is
          // also a "work in progress", so its not saved in the DB
          // When that happens we take the "main item" on the current page and find the themeRoot of it.
          contentRootHref = await getContentRootHref(item.href);
        }
        store.dispatch(initCurrentTheme(contentRootHref));
        await stateMatch((state) => {
          const status = selectTreeLoadingStatus(state);
          return status === 'succeeded' || status === 'failed';
        });
        const treeStatus = selectTreeLoadingStatus(store.getState());

        if (treeStatus === 'failed') {
          // The link could be a non-theme, like a shared database item
          const userAccess = getUserAccess(store.getState());
          const itemTree = await buildTreeFromHref(contentRootHref, userAccess);
          if (!itemTree) {
            routerService.transitionTo404();
          } else {
            routerService.goToPath(itemTree.$$meta.permalink, {
              scrollTo: link.replace('/content/', ''),
            });
          }
          loaderEnd();
          return;
        }

        const theme = selectCurrentThemeTree(store.getState());
        const flatTheme = flattenTree(theme);
        const leaf = flatTheme.find((instance) => instance.href === link);
        if (!leaf) {
          routerService.transitionTo404();
        } else {
          let pageParent = null;
          let currentLeaf = leaf;
          while (!pageParent) {
            if (currentLeaf.pageType) {
              pageParent = currentLeaf;
            } else {
              currentLeaf = currentLeaf.parent;
            }
          }
          if (pageParent) {
            routerService.goToPath(pageParent.pagePath, {
              scrollTo: link.replace('/content/', ''),
            });
          } else {
            routerService.goToPath(link);
          }
          loaderEnd();
        }
      }
    },
    [itemContents]
  );

  const piwikListener = (e) => {
    const href = e.target.href || e.target.parentNode.href || false;
    if (!href) return;

    if (href.match(/mailto:.*@katholiekonderwijs\.vlaanderen/)) {
      store.dispatch(
        trackPiwikEvent('contact_staff', { contact_method: 'click_email', contact_role: undefined })
      );
    }

    if (href.includes('/#!/nieuwsbrieven')) {
      store.dispatch(trackPiwikEvent('start_newsletter'));
    }

    if (href.includes('https://forms.office.com')) {
      store.dispatch(trackPiwikEvent('start_form'));
    }
    if (!e.target.classList.contains('js-is-download')) {
      const fileName = href.match(
        /.*(download\/content|pincette.katholiekonderwijs.vlaanderen|api.katholiekonderwijs.vlaanderen\/content).*\/([^/]+)$/
      );
      if (fileName?.[2]) {
        store.dispatch(trackPiwikEvent('download_file', getNameAndExtension(fileName[2])));
      }
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (domContainerRef.current) {
      const currentContainer = domContainerRef.current;
      currentContainer.addEventListener('click', piwikListener, true);
      currentContainer.addEventListener('click', followLink, true);
      return () => {
        currentContainer.removeEventListener('click', piwikListener, true);
        currentContainer.removeEventListener('click', followLink, true);
      };
    }
  }, [domContainerRef.current, followLink]);
};

export default useLinks;
