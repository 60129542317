import { createSlice } from '@reduxjs/toolkit';
import { SEARCH_LIMIT } from '@components/database/constants';

const initialState = {
  currentDatabaseItem: null,
  results: [],
  filters: {
    q: '',
    ageRangeFrom: 2,
    ageRangeTo: 18,
    zillItem: null,
    requirements: [],
    order: null,
    curricula: [],
  },
  limit: SEARCH_LIMIT,
};

initialState.tempFilters = { ...initialState.filters };

const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {
    setResults: (state, action) => {
      state.results = action.payload;
    },
    // setFilter changes just the tempFilter, in order to not trigger the search until the user clicks the search button
    // to trigger the search, applyTempFilter must be called
    setFilter: (state, action) => {
      if (Object.keys(initialState.filters).includes(action.payload?.fieldName)) {
        // If order is changed the change must be applied without pressing search
        // You can also set the property "applyFieldImmediately" when dispatching this action to apply that filter right away instead of set it first in tempFilters (used for subatomic selector)
        if (action.payload?.fieldName === 'order' || action.payload.applyFieldImmediately) {
          state.filters = {
            ...state.filters,
            [action.payload.fieldName]: action.payload.payload,
          };
        }

        state.tempFilters = {
          ...state.tempFilters,
          [action.payload.fieldName]: action.payload.payload,
        };
      }
    },
    setLimit: (state, action) => {
      switch (action.payload.type) {
        case 'next':
          state.limit += SEARCH_LIMIT;
          break;
        case 'reset':
          state.limit = SEARCH_LIMIT;
          break;
        case 'custom':
          state.limit = parseInt(action.payload.value, 10);
          break;
        default:
          console.log('Unknown setLimit payload');
      }
    },
    // This changes the actual filters, and that change triggers the search
    applyTempFilter: (state) => {
      state.filters = { ...state.tempFilters };
    },
    revertTempFiltersToLastApplied: (state) => {
      state.tempFilters = { ...state.filters };
    },
    // this is not the same as "setFilter" method because, in this case, we are actually changing the filter while, in the other one, the filters are applied when the "applyTempFilter" action is triggered
    setInitialDatabaseRequirements: (state, action) => {
      state.filters = { ...state.filters, requirements: action.payload };
      state.tempFilters = { ...state.filters };
    },
    resetFilters: (state, action = {}) => {
      const clearedRequirements = action.payload?.map((requirement) => ({
        ...requirement,
        values: requirement.values.map((r) => ({ ...r, checked: false })),
      }));

      if (clearedRequirements) {
        state.filters = { ...initialState.filters, requirements: clearedRequirements };
      } else {
        state.filters = { ...initialState.filters };
      }

      state.tempFilters = { ...state.filters };
      state.limit = SEARCH_LIMIT;
    },
    setCurrentSharedDataBaseItem: (state, action) => {
      state.currentDatabaseItem = action.payload;
    },
  },
});

export const databaseActions = databaseSlice.actions;
export default databaseSlice;
