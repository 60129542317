import settings from '@src/config/settings';

import logo from '@src/assets/images/theme_logo_ko_vl.png';
import smartschoolLogo from '@src/assets/images/smartschool_original.png';
import schoolwareLogo from '@src/assets/images/schoolware_logo.png';
import office365logo from '@src/assets/images/office-365.png';

import { getEducationLevels, getRegions } from '@src/components/constants';
import { getTargetGroups, getUserResponsibilities } from '@src/reduxStore/user/userDataAccess';
import {
  getVOSOus,
  getVOSEquivalentOuToSAMOu,
  getSAMOus,
} from '@src/reduxStore/content/organisationsDataAccess';
import { getNonAbolishedResources } from '@kathondvla/sri-client/date-utils';
import { ORDERED_USER_TARGET_GROUPS } from '@src/reduxStore/analytics/analyticsSlice';
import ORGANISATION_TYPES_BY_URL_TYPE from '@src/reduxStore/user/userConstants';
import { getUserDefaultRegions, getUserDefaultEducationLevels } from './contextFilterHelper';
import { getUserDiocesesHrefs, getUserMainstructuresHrefs } from './organisationsHelper';

const isVOSMegaOrganisation = (organisation) =>
  ['GOVERNINGINSTITUTION', 'SCHOOLCOMMUNITY'].includes(organisation.$$meta.type);

const loginMethods = {
  KATHONDVLA: '9111b767-bba3-48d2-a2e8-dcbe43399e7f',
  SMART_SCHOOL: 'bd0f545a-71a0-11e9-845d-9fdda140c8d8',
  SCHOOL_WARE: '1b0760e8-71a1-11e9-b9f8-43f786c5ce5d',
  OFFICE_365: '1b0760e8-71a1-11e9-b9f8-43f786c5ce5d',
};

const alias = {
  SCHOOL_WARE: 'SCHOOLWARE',
  OFFICE_365: 'OFFICE365',
};

export function getLoginUrl() {
  const url = `${settings.oauth.authenticate}?scope=${
    settings.oauth.scope
  }&response_type=none&client_id=${settings.oauth.clientID}&redirect_uri=${
    settings.oauth.redirectUri
  }&state=${encodeURIComponent(window.location.href)}`;

  return url;
}

export function getLoginProviderIcon(provider) {
  switch (provider) {
    case 'SMART_SCHOOL':
      return smartschoolLogo;
    case 'SCHOOL_WARE':
      return schoolwareLogo;
    case 'OFFICE_365':
      return office365logo;
    default:
      return logo;
  }
}

export function redirectToLoginProvider(provider) {
  window.location.href = `${settings.oauth.authenticate}?scope=${
    settings.oauth.scope
  }&response_type=none&client_id=${settings.oauth.clientID}&redirect_uri=${
    settings.oauth.redirectUri
  }&state=${encodeURIComponent(window.location.href)}&login_provider=${loginMethods[provider]}${
    alias[provider] ? `&loginProviderAlias=${alias[provider]}` : ''
  }`;
}

export function logoutCurrentUser() {
  window.localStorage.removeItem('privateState');
  return `${settings.oauth.oauthURL}/logout?redirect_uri=${window.location.origin}`;
}

/**
 * This function returns the VOS Organisational Units hrefs equivalent to a given SAM organisational units hrefs
 * @param {Array.<any>} userResponsibilities
 * @param {Array.<any>} userSAMOus
 * @returns {Promise.Array<any>}
 */
const getVOSOusHrefsFromSAM = async (SAMOus) => {
  const VOSOus = await getVOSEquivalentOuToSAMOu(
    SAMOus.map((SAMOu) => SAMOu.$$meta.permalink)
  ).then((VOSOusResp) =>
    VOSOusResp.filter((VOSou) => VOSou && VOSou.educationLevel !== 'SECUNDAIR')
  );

  return VOSOus.reduce((VOSousHrefs, VOSOu) => {
    // Makes sense that a SAM GOVERNING_INSTITUTION is represented in VOS with a type different than GOVERNING_INSTITUTION?
    // If no then the following line should always return an empty array because we are filtering SAM GOVERNING_INSTITUTION only at the top of this function
    if (!isVOSMegaOrganisation(VOSOu)) {
      VOSousHrefs.push(VOSOu.$$meta.permalink);
      return VOSousHrefs;
    }
    if (isVOSMegaOrganisation(VOSOu)) {
      if (VOSOu.$$schools?.length) {
        const activeSimpleVOSOus = getNonAbolishedResources(VOSOu.$$schools);
        activeSimpleVOSOus.forEach(
          (VOSOuChild) =>
            !VOSousHrefs.includes(VOSOuChild.href) && VOSousHrefs.push(VOSOuChild.href)
        );
      }
    }
    return VOSousHrefs;
  }, []);
};

export const constructViewOptionsFromUser = async (userKey, isKOVUser) => {
  const userResponsibilities = await getUserResponsibilities(`/persons/${userKey}`);

  // if the user has responsibilities on SAM GoverningInstitutions we find the OrganisationalUnits hrefs on VOS related to those ones
  const SAMOusHrefs = userResponsibilities
    .filter((resp) => resp.organisation.href.match(/^\/sam\//g))
    .map((resp) => resp.organisation.href);

  const SAMOus = SAMOusHrefs.length
    ? await getSAMOus(SAMOusHrefs).then((resp) =>
        resp.filter((ou) => ['LEERSTEUNCENTRUM', 'GOVERNINGINSTITUTION'].includes(ou.type))
      )
    : [];

  const VOSOusHrefsFromSAM = SAMOus.length ? await getVOSOusHrefsFromSAM(SAMOus) : [];

  // then we get the hrefs of the VOS ones
  const VOSOusHrefs = userResponsibilities
    .filter((resp) =>
      resp.organisation.href.match(/^\/(schools|cvos|boardings|schoolcommunities)\//g)
    )
    .map((resp) => resp.organisation.href);

  // after that we ask the API for all those VOS hrefs (including the one we obtained from the SAM GOVERNING_INSTITUTION)
  const VOSOus = await getVOSOus([...new Set([...VOSOusHrefsFromSAM, VOSOusHrefs])].flat());

  // We get the dioceses and the mainstructures based on the user organisations. Depending if they re on SAM or VOS we will have to treat them different to get the same information
  // Dioceses => Used for getting the user default regions
  // Mainstructures => User for getting the user default education levels
  const [diocesesHrefs, mainstructuresHrefs] = await Promise.all([
    getUserDiocesesHrefs(SAMOus, VOSOus),
    getUserMainstructuresHrefs(SAMOusHrefs, VOSOus),
  ]);

  if (diocesesHrefs.length === 0 && mainstructuresHrefs.length === 0 && isKOVUser) {
    // if no preferences yet and user is KOV then we select all of them
    return {
      REGIONS: getRegions().map((e) => ({ ...e, checked: true })),
      EDUCATION_LEVELS: getEducationLevels().map((e) => ({ ...e, checked: true })),
    };
  }

  return {
    REGIONS: getUserDefaultRegions(diocesesHrefs),
    EDUCATION_LEVELS: getUserDefaultEducationLevels(mainstructuresHrefs, VOSOus, SAMOus),
  };
};

/**
 * This method will receive the user responsibilities and will filter them in order to get only the relevants one for the "userTargetGroup" "calculation" (not all responsibilities have to be taken into account)
 * @param {*} userResponsibilities
 */
export const getfilteredUserResponsibilitiesWithType = async (userResps) => {
  // from the initial set of responsibilities we get only a "closed" lists of them (the other ones are not relevant for the userTargetGroup calculation)
  // also we keep only the information we need from the original responsibilities plus we set up the type in some cases based on a constant list
  let userRespsWithType = userResps.reduce((accUserRespsWithType, userResp) => {
    const urlType = userResp?.organisation?.href?.replace(/^\/(.+?)\/.+/, '$1');
    if (ORGANISATION_TYPES_BY_URL_TYPE[urlType] || urlType === 'sam') {
      const { position, organisation } = userResp;
      if (position && organisation) {
        accUserRespsWithType.push({
          positionHref: position.href,
          organisationHref: organisation.href,
          organisationType: ORGANISATION_TYPES_BY_URL_TYPE[urlType] || 'SAM',
        });
      }
    }
    return accUserRespsWithType;
  }, []);

  // we get the "SCHOOL" type ones because we should only include the ones that are not "SECONDAIR" (we got them from SAM API)
  // In a later change it was decided that "/cvos" will be also type "SCHOOL" but for those we don't need to call VOS API, that why we add now the "organisationHref" including "school" as a condition
  const userRespsSchoolOrgHrefs = userRespsWithType
    .filter(
      (resp) =>
        resp.organisationType === 'SCHOOL' &&
        ['/schools', '/cvos'].some((substring) => resp.organisationHref.includes(substring))
    )
    .map((resp) => resp.organisationHref);

  if (userRespsSchoolOrgHrefs.length) {
    const schoolOrgsHrefs = await getVOSOus(userRespsSchoolOrgHrefs).then((organisations) =>
      organisations
        .filter((organisation) => organisation?.educationLevel !== 'SECUNDAIR')
        .map((organisation) => organisation?.$$meta.permalink)
    );

    // we remove the one that don't have to be there from the existing list
    userRespsWithType = userRespsWithType.filter(
      (userRespWithType) =>
        userRespWithType.organisationType !== 'SCHOOL' ||
        schoolOrgsHrefs.includes(userRespWithType.organisationHref)
    );
  }

  // we get the "SAM" ones because we need to check the API in order to get the "right" organisationType
  const userRespsSAMOrgHrefs = userRespsWithType
    .filter((resp) => resp.organisationType === 'SAM')
    .map((resp) => resp.organisationHref);

  if (userRespsSAMOrgHrefs.length) {
    const SAMOrgs = await getSAMOus(userRespsSAMOrgHrefs);
    userRespsWithType = userRespsWithType.map((userRespWithType) => {
      const SAMorg = SAMOrgs.find((s) => s.$$meta.permalink === userRespWithType.organisationHref);

      // for the "SAM" ones we return the type from the SAM API response
      return {
        ...userRespWithType,
        ...(SAMorg && { organisationType: SAMorg.type }),
      };
    });
  }
  return userRespsWithType;
};

/**
 * This function extract and map to an object the information we need from the targetGroups API response.
 * @param {*} targetGroups
 * @returns
 */
export const getTargetGroupsRelevantInformation = (targetGroups) =>
  targetGroups
    .map((targetGroup) => ({
      name: targetGroup.name,
      href: targetGroup.$$meta.permalink,
      positions: targetGroup.selectors
        ?.filter((tgSelector) => tgSelector.type === 'FIXED_LIST')
        .map((tgSelector) => tgSelector.value)
        .flat()
        .map((tgSelector) => ({
          onlyAppliesForOUTypes: tgSelector.ouTypes,
          href: tgSelector.position,
        })),
    }))
    .filter((targetGroup) => targetGroup.positions);

export const getUserTargetGroup = async (userKey) => {
  // we get the user responsibilities together with the target groups we are interested in
  const [userPositions, userTargetGroups] = await Promise.all([
    getUserResponsibilities(`/persons/${userKey}`),
    getTargetGroups(),
  ]).then(async ([userResponsibilities, targetGroups]) => {
    const builtUserPositions =
      (userResponsibilities?.length &&
        (await getfilteredUserResponsibilitiesWithType(userResponsibilities))) ||
      [];

    const builtUserTargetGroups =
      (targetGroups?.length && getTargetGroupsRelevantInformation(targetGroups)) || [];

    return [builtUserPositions, builtUserTargetGroups];
  });

  if (!(userPositions?.length > 0 && userTargetGroups?.length > 0)) return false;

  // we cross the positions of the user with the target groups and the organisation types for which they apply and order them based on the criteria provided for this property. Finally we return only the first positions, which will be the "highest" position that the user has towards an institution
  return userTargetGroups
    .filter((targetGroup) =>
      targetGroup.positions.some((tgPosition) =>
        userPositions.some((userPos) => {
          const positionMatch = userPos.positionHref === tgPosition.href;
          const OUTypeMatch =
            !tgPosition.onlyAppliesForOUTypes ||
            tgPosition.onlyAppliesForOUTypes?.includes(userPos.organisationType);
          return positionMatch && OUTypeMatch;
        })
      )
    )
    .sort(
      (a, b) =>
        ORDERED_USER_TARGET_GROUPS.indexOf(a.href) - ORDERED_USER_TARGET_GROUPS.indexOf(b.href)
    )[0];
};
